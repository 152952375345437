import { flow, getRoot, types } from "mobx-state-tree"
import Entrant from './models/Entrant'
import { callApi, query } from '../utils'

const EntrantStore = types.model('EntrantStore', {
    page: types.maybe(types.integer),
    limit: types.maybe(types.integer),
    total: types.maybe(types.integer),
    pages: types.map(types.array(types.reference(Entrant))),
    selectedPage: types.maybe(types.integer),
    searchQuery: types.maybe(types.string),
}).actions(self => ({

    loadEntrants: flow(function* ({ pageNumber } = {}) {
        const { data, total, limit, page } = yield callApi({
            endpoint: `/entrants${query({
                page: pageNumber,
                query: self.searchQuery,
            })}`,
            token: self.token,
        })

        const ids = self.entities.add('entrants', data)
        self.page = page
        self.limit = limit
        self.total = total
        self.pages.set(page, ids)
        self.selectedPage = page
    }),

    reload: flow(function* () {
        self.reset()
        yield self.loadEntrants()
    }),

    search: flow(function* () {
        self.reset()
        yield self.loadEntrants()
    }),

    reset() {
        self.selectedPage = undefined
        self.pages = {}
        self.entities.clear('entrants')
    },

    setSearch(value) {
        self.searchQuery = value

        if (self.searchDebounce) {
            clearTimeout(self.searchDebounce)
        }

        self.searchDebounce = setTimeout(() => {
            self.search()
        }, 800)
    }

})).views(self => ({

    get entities() {
        return getRoot(self).entityStore
    },

    get tableData()
    {
        return self.pages.get(self.selectedPage) || []
    },

    get numberOfPages() {
        return Math.ceil(self.total / self.limit)
    },

    get token() {
        return getRoot(self).authStore.token
    }

})).volatile(self => ({

    searchDebounce: null,

}))

export default EntrantStore
