import { useState } from 'react'
import { useStore } from '../state/RootStore'
import { observer } from 'mobx-react-lite'

function LoginContainer() {
    const [token, setToken] = useState()
    const { authStore } = useStore()

    const authenticate = () => {
        authStore.setToken(token)
    }

    return <div className='flex justify-center items-center w-full h-full'>
        <div
            className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-center"
        >
            <h5 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Video Diplom Login
            </h5>

            {authStore.error && <div
                className="flex p-2 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
                role="alert">
                <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                    />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    <span className="font-medium">Couldn't login!</span>
                </div>
            </div>}

            <div className='mb-2'>
                <input
                    type="text"
                    id="token"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Token"
                    value={token}
                    required
                    onChange={e => {
                        setToken(e.target.value)
                    }}
                />
            </div>
            <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full"
                onClick={authenticate}
            >
                Go
            </button>
        </div>
    </div>
}

export default observer(LoginContainer)
