import { types } from "mobx-state-tree"
import VictoryPhoto from './VictoryPhoto'
import Checkin from './Checkin'

const Diploma = types.model('Diploma', {
    was_successful: types.boolean,
    was_uploaded: types.boolean,
    filename: types.string,
    url: types.maybeNull(types.string),
    victory_photo: types.maybeNull(VictoryPhoto),
    checkins: types.array(Checkin),
})

export default Diploma
