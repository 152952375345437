import './App.css';
import RootStore, { RootStoreProvider } from './state/RootStore'
import { useEffect, useState } from 'react'
import DashboardContainer from './containers/DashboardContainer'
import LoginContainer from './containers/LoginContainer'
import { observer } from 'mobx-react-lite'

function App() {
    const [isReady, setIsReady] = useState(false)
    const { isAuthenticated, setToken } = RootStore.authStore

    useEffect(() => {
        document.body.className = 'antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900';

        const hydratedToken = localStorage.getItem('vd_token')

        if (hydratedToken) {
            setToken(hydratedToken)
        }

        setIsReady(true)
    }, [setToken])

  return (
      <RootStoreProvider value={RootStore}>
          {isReady ? (isAuthenticated ? <DashboardContainer /> : <LoginContainer />) : null}
      </RootStoreProvider>
  );
}

export default observer(App);
