import { flow, types } from "mobx-state-tree"
import { callApi } from '../utils'

const AuthStore = types.model('AuthStore', {
    token: types.maybeNull(types.string),
    error: false,
}).actions(self => ({

    setToken: flow(function* (value) {

        try {
            yield callApi({
                endpoint: '/me',
                token: value,
            })

            self.error = false
            self.token = value
            localStorage.setItem('vd_token', value)
        } catch (e) {
            self.error = true
            self.token = null
        }
    }),

    unsetToken() {
        localStorage.removeItem('vd_token')
        self.token = null
    }

})).views(self => ({

    get isAuthenticated() {
        return !!self.token
    },

}))

export default AuthStore
