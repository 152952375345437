import { types } from "mobx-state-tree"
import VictoryPhoto from './VictoryPhoto'
import Diploma from './Diploma'

export const DIPLOMA_STATUS_PENDING = 'pending'
export const DIPLOMA_STATUS_GENERATED = 'generated'
export const DIPLOMA_STATUS_FAILED = 'failed'
export const DIPLOMA_STATUS_UPLOADED = 'uploaded'

const Entrant = types.model('Entrant', {
    id: types.identifierNumber,
    first_name: types.string,
    last_name: types.string,
    entry_id: types.string,
    victory_photo: types.maybeNull(VictoryPhoto),
    diploma: types.maybeNull(Diploma),
}).views(self => ({

    get fullName() {
        return `${self.first_name} ${self.last_name}`
    },

    get hasVictoryPhoto() {
        return !!self.victory_photo
    },

    get diplomaGenerated() {
        return !!self.diploma
    },

    get diplomaStatus() {
        if (self.diploma?.was_uploaded) {
            return DIPLOMA_STATUS_UPLOADED
        }

        if (self.diploma?.was_successful) {
            return DIPLOMA_STATUS_GENERATED
        } else if (self.diploma) {
            return DIPLOMA_STATUS_FAILED
        }

        return DIPLOMA_STATUS_PENDING
    }

}))

export default Entrant
