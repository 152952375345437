import ProgressBar from '../components/ProgressBar'
import RootStore, { useStore } from '../state/RootStore'
import Table from '../components/entrants/Table'
import { useEffect } from 'react'
import { postDownload } from '../utils'

function DashboardContainer() {
    const { entrantStore, authStore } = useStore()

    useEffect(() => {
        RootStore.statsStore.loadStats()
    }, [])

    return <div className='p-20'>
        <div className='flex flex-col gap-10'>
            <div className='flex justify-between'>
                <div style={{ flex: 1, maxWidth: 300 }}>
                    <ProgressBar />
                </div>

                <div>
                    <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => entrantStore.reload()}
                    >
                        Refresh
                    </button>
                    <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => {
                            postDownload('/export', 'diplomas.csv')
                        }}
                    >
                        Export
                    </button>
                    <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => authStore.unsetToken()}
                    >
                        Exit
                    </button>
                </div>
            </div>
            <Table />
        </div>
    </div>
}

export default DashboardContainer
