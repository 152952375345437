import { types } from "mobx-state-tree"
import Entrant from './models/Entrant'
import { keyBy } from 'lodash'

const EntityStore = types.model('EntityStore', {
    entrants: types.map(Entrant),
}).actions(self => ({

    push(attr, values) {
        self[attr] = [
            ...self[attr],
            ...values,
        ]
    },

    add(entity, entities, identifier = 'id') {

        const identifiers = []

        self[entity].merge(
            keyBy(entities, e => {
                identifiers.push(e[identifier])

                return e[identifier]
            }),
        )

        return identifiers
    },

    clear(entity) {
        self[entity].clear()
    },

}))

export default EntityStore
