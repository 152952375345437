import { useContext, createContext } from 'react'
import { types } from "mobx-state-tree"
import EntityStore from './EntityStore'
import EntrantStore from './EntrantStore'
import StatsStore from './StatsStore'
import AuthStore from './AuthStore'

const RootStore = types.model('RootStore', {
    entityStore: types.optional(EntityStore, () => EntityStore.create({})),
    entrantStore: types.optional(EntrantStore, () => EntrantStore.create({})),
    statsStore: types.optional(StatsStore, () => StatsStore.create({})),
    authStore: types.optional(AuthStore, () => AuthStore.create({})),
}).actions(self => ({



})).create()

const RootStoreContext = createContext(null)
export const RootStoreProvider = RootStoreContext.Provider

export function useStore() {
    const store = useContext(RootStoreContext)

    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider')
    }

    return store
}

export default RootStore
