import { observer } from 'mobx-react-lite'
import { useStore } from '../state/RootStore'

function ProgressBar() {
    const { statsStore } = useStore()
    const percentLabel = `${statsStore.percent || 0}%`
    const { completed_diplomas, total_entrants } = statsStore

    return <div>
        <div className="mb-1 text-xs text-blue-100 dark:text-white">{completed_diplomas} / {total_entrants}</div>

        <div className="w-full h-6 mb-4 bg-gray-200 rounded-full dark:bg-gray-700">

            <div
                className="h-6 bg-blue-600 rounded-full dark:bg-blue-500 text-xs flex justify-center items-center leading-none font-medium text-blue-100"
                style={{ width: percentLabel }}
            >{percentLabel}</div>
        </div>
    </div>
}

export default observer(ProgressBar)
