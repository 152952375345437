import { flow, getRoot, types } from "mobx-state-tree"
import { callApi } from '../utils'

const StatsStore = types.model('StatsStore', {
    total_entrants: types.maybe(types.integer),
    completed_diplomas: types.maybe(types.integer),
    percent: types.maybe(types.integer),
}).actions(self => ({

    loadStats: flow(function* ({ pageNumber } = {}) {
        const { total_entrants, completed_diplomas, percent } = yield callApi({
            endpoint: '/stats',
            token: self.token,
        })

        self.total_entrants = total_entrants
        self.completed_diplomas = completed_diplomas
        self.percent = percent
        self.reload()
    }),

    reload() {
        if (self.reloadTimer) {
            clearTimeout(self.reloadTimer)
        }

        self.reloadTimer = setTimeout(() => {
            self.loadStats()
        }, 6000)
    }

})).views(self => ({

    get token() {
        return getRoot(self).authStore.token
    }

})).volatile(self => ({

    reloadTimer: null,

}))

export default StatsStore
