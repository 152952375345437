import { useStore } from '../../state/RootStore'
import { observer } from 'mobx-react-lite'
import {
    DIPLOMA_STATUS_FAILED,
    DIPLOMA_STATUS_GENERATED,
    DIPLOMA_STATUS_PENDING,
    DIPLOMA_STATUS_UPLOADED
} from '../../state/models/Entrant'
import { useEffect } from 'react'

function HasVictoryPhoto({ entrant }) {
    const onClick = () => {
        window.open(entrant.victory_photo.storage_url, '_blank');
    }

    return <span
        className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 cursor-pointer"
        onClick={onClick}
    >Yes</span>
}

function NoVictoryPhoto() {
    return <span
        className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 disabled">No</span>
}

function VictoryPhotoStatus({ entrant }) {
    if (entrant.hasVictoryPhoto) {
        return <HasVictoryPhoto entrant={entrant} />
    }

    return <NoVictoryPhoto />
}

function DiplomaStatus({ entrant }) {
    const { diplomaStatus } = entrant

    const openDiploma = () => {
        window.open(entrant.diploma.url, '_blank');
    }

    if (diplomaStatus === DIPLOMA_STATUS_UPLOADED) {
        return <span
            className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 cursor-pointer"
            onClick={openDiploma}
        >Uploaded</span>
    } else if (diplomaStatus === DIPLOMA_STATUS_GENERATED) {
        return <span
            className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Generated</span>
    } else if (diplomaStatus === DIPLOMA_STATUS_FAILED) {
        return <span
            className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Error</span>
    } else if (diplomaStatus === DIPLOMA_STATUS_PENDING) {
        return <span
            className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
        >Pending</span>
    }
}

function paginate(current, max) {
    if (!current || !max) return {
        current: null,
        prev: null,
        next: null,
        items: [],
    }

    let prev = current === 1 ? null : current - 1,
        next = current === max ? null : current + 1,
        items = [1]

    if (current === 1 && max === 1) return {current, prev, next, items}
    if (current > 4) items.push('…')

    let r = 2, r1 = current - r, r2 = current + r

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) items.push(i)

    if (r2 + 1 < max) items.push('…')
    if (r2 < max) items.push(max)

    return { current, prev, next, items }
}

function PaginationPage({ text, page, position, active }) {
    const { entrantStore } = useStore()
    const { loadEntrants } = entrantStore
    let styles = active
        ? ['px-3 py-2 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white']
        : ['px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white']

    if (position === 'right') {
        styles.push('rounded-r-lg')
    } else if (position === 'left') {
        styles.push('rounded-l-lg')
    }

    return <li>
        <a
            href="#"
            className={styles.join(' ')}
            onClick={() => {
                if (typeof page == 'number') {
                    loadEntrants({ pageNumber: page })
                }
            }}
        >{text}</a>
    </li>
}

const Pagination = observer(() => {

    const { entrantStore } = useStore()
    const { selectedPage, numberOfPages } = entrantStore
    const { current, prev, next, items } = paginate(selectedPage, numberOfPages)

    return <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px">
            {prev && <PaginationPage text='Previous' page={prev} position='left' />}
            {items.map(page => (
                <PaginationPage text={page} page={page} active={page === current} key={page} />
            ))}
            {next && <PaginationPage text='Next' page={next} position='right' />}
        </ul>
    </nav>
})

const Search = observer(() => {
    const { entrantStore } = useStore()
    const { searchQuery, setSearch } = entrantStore

    return <div className="flex items-center">
        <label htmlFor="simple-search" className="sr-only">Search</label>
        <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"></path>
                </svg>
            </div>
            <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearch(e.target.value)}
            />
        </div>
    </div>
})

function Table() {
    const { entrantStore } = useStore()

    useEffect(() => {
        entrantStore.loadEntrants()
    }, [entrantStore])

    return <div className='flex flex-col gap-10'>
        <div className='flex justify-between items-center'>
            <Search />
            <Pagination />
        </div>

        <table className="border-collapse table-auto w-full text-sm">
            <thead>
            <tr>
                <th className='border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left'>Name</th>
                <th className='border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left'>Victory photo</th>
                <th className='border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left'>Diploma status</th>
            </tr>
            </thead>
            <tbody className='bg-white dark:bg-slate-800'>
                {entrantStore.tableData.map(entrant => <tr key={entrant.id}>
                    <td className='border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200'>{entrant.fullName}</td>
                    <td className='border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200'><VictoryPhotoStatus entrant={entrant} /></td>
                    <td className='border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200'><DiplomaStatus entrant={entrant} /></td>
                </tr>)}
            </tbody>
        </table>

        <Pagination />
    </div>
}

export default observer(Table)
