import RootStore from './state/RootStore'

export const query = (parameters = {}) => {
    const keys = Object.keys(parameters).filter(v => {
        return v !== null && parameters[v] !== null && parameters[v] !== undefined
    })

    const query = keys.map((key) => {
        let value = parameters[key]

        if (Array.isArray(value)) {
            const values = value.filter((v) => v !== null && v !== undefined)
            value = values.join(',')
        }

        return key + '=' + value
    }).join('&')

    return query.length ? '?' + query : ''
}

export const sort = (by, direction) => {
    const _direction = direction === 'desc' ? '-' : ''

    return by !== null && direction !== null ? (_direction + by) : by
}

const resolveEndpoint = (path) => {
    const url = process.env.NODE_ENV === 'development' ? 'http://dev.diplom' : 'https://diplom.dk';

    return `${url}${path}`
}

export const callApi = async ({ endpoint, method = 'get', token }) => {

    const response = await fetch(resolveEndpoint(endpoint), {
        method,
        headers: {
            ...token ? { 'Authorization': token } : {}
        }
    })

    if (response.status > 299) {
        throw new Error('API error')
    }

    return await response.json()
}

export const postDownload = async (path, filename, data) => {

    const token = RootStore.authStore.token
    const endpoint = resolveEndpoint(path)

    const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
        },
    })

    if (response.status < 200 || response.status > 299) {
        throw new Error('Error occurred during postDownload.')
    }

    const content = await response.blob()

    const url = window.URL.createObjectURL(content)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}
